/* eslint-disable react/no-unescaped-entities */
import React from 'react';
export const press = (
	<div>
		<p>
			2018
			<br />
			<span className='body'>
				<strong>The Verge</strong>
				<br />
				The Key to Creating Gorgeous, Glitchy YouTube Images: Anticipation and
				Deletion
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.theverge.com/2018/7/15/17564588/datamosh-youtubeartifacts-glitch-art-kraftsow'
				>
					https://www.theverge.com/2018/7/15/17564588/datamosh-youtubeartifacts-glitch-art-kraftsow
				</a>
			</span>
		</p>
		<p>
			2018
			<br />
			<span className='body'>
				<strong>Tina Roth Eisenberg</strong>
				<br />
				"Someone hire this genius."
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://twitter.com/swissmiss/status/976900159929044992'
				>
					https://twitter.com/swissmiss/status/976900159929044992
				</a>
			</span>
		</p>
		<p>
			2018
			<br />
			<span className='body'>
				<strong>Prosthetic Knowledge</strong>
				<br />
				YouTube Artifacts Latest Exhibtion From MoMAR
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='http://prostheticknowledge.tumblr.com/post/174539715396/youtube-artifacts-latest-ar-exhibition-from-momar'
				>
					http://prostheticknowledge.tumblr.com/post/174539715396/youtube-artifacts-latest-ar-exhibition-from-momar
				</a>
			</span>
		</p>
		<p>
			2017
			<br />
			<span className='body'>
				<strong>Engadget</strong>
				<br />
				The Big Picture: Twitter bot's 'Glitch Art' is a Digital Impressionist
				Dream
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.engadget.com/2017/10/09/david-kraftsow-youtube-artifact-glitch-art-twitter-bot/'
				>
					https://www.engadget.com/2017/10/09/david-kraftsow-youtube-artifact-glitch-art-twitter-bot/
				</a>
			</span>
		</p>
		<p>
			2017
			<br />
			<span className='body'>
				<strong>Electric Objects</strong>
				<br />
				Artist Interview: David Kraftsow
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='http://blog.electricobjects.com/post/160811304660/david-kraftsow'
				>
					http://blog.electricobjects.com/post-160811304660/david-kraftsow
				</a>
			</span>
		</p>
		<p>
			2015
			<br />
			<span className='body'>
				<strong>HP Zero Day Initiative</strong>
				<br />
				Adobe Flash Sound Universal Cross Site Scripting Vulnerability
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='http://zerodayinitiative.com/advisories/ZDI-15-294/'
				>
					http://zerodayinitiative.com/advisories/ZDI-15-294/
				</a>
			</span>
		</p>
		<p>
			2015
			<br />
			<span className='body'>
				<strong>Brutalist Websites</strong>
				<br />
				dontsave.com Interview
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='http://brutalistwebsites.com/dontsave.com/'
				>
					http://brutalistwebsites.com/dontsave.com/
				</a>
			</span>
		</p>
		<p>
			2014
			<br />
			<span className='body'>
				<strong>GQ</strong>
				<br />
				Time Waster of the Day: First-Person Tetris
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.gq.com/story/time-waster-of-the-day-first-person-tetris'
				>
					https://www.gq.com/story/time-waster-of-the-day-first-person-tetris
				</a>
			</span>
		</p>
		<p>
			2014
			<br />
			<span className='body'>
				<strong>The FWA</strong>
				<br />
				Same Days Forever
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://thefwa.com/cases/same-days-forever'
				>
					https://thefwa.com/cases/same-days-forever
				</a>
			</span>
		</p>
		<p>
			2013
			<br />
			<span className='body'>
				<strong>Juxtapoz</strong>
				<br />
				Levi's Sends Fans on a Journey of Self-Discovery With #MAKEOURMARK
				Social Projects
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.juxtapoz.com/news/levis-sends-fans-on-a-journey-of-self-exploration-with-makeourmark/'
				>
					https://www.juxtapoz.com/news/levis-sends-fans-on-a-journey-of-self-exploration-with-makeourmark/
				</a>
			</span>
		</p>
		<p>
			2012
			<br />
			<span className='body'>
				<strong>Rhizome</strong>
				<br />
				Artist Profile: David Kraftsow
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='http://rhizome.org/editorial/2012/may/01/artist-profile-david-kraftsow/'
				>
					http://rhizome.org/editorial-2012/may/01/artist-profile-david-kraftsow/
				</a>
			</span>
		</p>
		<p>
			2012
			<br />
			<span className='body'>
				<strong>University of Amsterdam Masters of Media</strong>
				<br />
				David Kraftsow, Justin Bieber, and the Evolution of Curatorial Practice
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://mastersofmedia.hum.uva.nl/blog/2012/10/04/david-kraftsow-justin-bieber-
					and-the-evolution-of-curatorial-practice/'
				>
					https://mastersofmedia.hum.uva.nl/blog-2012/10/04/david-kraftsow-justin-bieber-and-the-
					evolution-of-curatorial-practice/
				</a>
			</span>
		</p>
		<p>
			2011
			<br />
			<span className='body'>
				<strong>Mashable</strong>
				<br />
				Like Jazz? Use YouTube To Create Your Own Jams
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://mashable.com/2011/08/01/youtube-free-jazz/#7i60NxkHpOqM'
				>
					https://mashable.com/2011/08/01/youtube-free-jazz/#7i60NxkHpOqM
				</a>
			</span>
		</p>
		<p>
			2011
			<br />
			<span className='body'>
				<strong>Pee-wee Herman</strong>
				<br />
				"Presenting: free jazz generator!"
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://twitter.com/peeweeherman/status/97696502150991873'
				>
					https://twitter.com/peeweeherman/9769650215099
				</a>
			</span>
		</p>
		<p>
			2010
			<br />
			<span className='body'>
				<strong>Entertainment Weekly</strong>
				<br />
				Item #4 on EW's "Must List" for the week of January 29, 2010
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://firstpersontetris.com/press/ew_must_list.jpg'
				>
					https://firstpersontetris.com/press/ew_must_list.jpg
				</a>
			</span>
		</p>
		<p>
			2010
			<br />
			<span className='body'>
				<strong>Gizmodo</strong>
				<br />
				This Tetris Will Make You Vomit
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://gizmodo.com/5448603/this-tetris-will-make-you-vomit'
				>
					https://gizmodo.com/5448603/this-tetris-will-make-you-vomit
				</a>
			</span>
		</p>
		<p>
			2010
			<br />
			<span className='body'>
				<strong>Jimmy Wales</strong>
				<br />
				"Seriously awesome: first person tetris"
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://twitter.com/jimmy_wales/status/7909231983'
				>
					https://twitter.com/jimmy_wales/7909231983
				</a>
			</span>
		</p>
		<p>
			2009
			<br />
			<span className='body'>
				<strong>Wired Magazine</strong>
				<br />
				Yooouuutuuube Takes YouTube on Psychedelic Trip
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.wired.com/2009/05/yooouuutuuube-turns-videos-into-trippy-moving-mosaics/'
				>
					https://www.wired.com/2009/05/yooouuutuuube-turns-videos-into-trippy-moving-mosaics/
				</a>
			</span>
		</p>
		<p>
			2009
			<br />
			<span className='body'>
				<strong>Rhizome</strong>
				<br />
				Interview with David Kraftsow
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='http://rhizome.org/editorial/2009/jun/4/interview-with-david-kraftsow/'
				>
					http://rhizome.org/editorial/2009/jun/4/interview-with-david-kraftsow/
				</a>
			</span>
		</p>
	</div>
);
