import styled from 'styled-components';

export const CutInDiv = styled.div<{ delay?:number }>`
	@keyframes cutIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	opacity: 0;
	animation-name: cutIn;
	animation-duration: 0.12s;
	animation-delay: ${p => p.delay || 0}s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
`;
