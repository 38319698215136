/* eslint-disable react/no-unescaped-entities */
import React from 'react';
export const projects = (
	<div>
		<em>Selected projects. Full portfolio upon request.</em>
		<br />
		<br />
		<p>
			<strong>Kraken</strong>
			<br />
			<span className='with'>with Kraken &amp; Bartle Bogle Hegarty</span>
			<br />
			<span className={'body'}>
				Provided React consultation, component dev, websocket API integration
				and styling work on a reboot of a major cryptocurrency platform's web
				presence.
				<br />
				<a target='_blank' rel='noopener noreferrer' href='https://kraken.com'>
					https://kraken.com
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>CenterStage</strong>
			<br />
			<span className='with'>with Samsung &amp; The Barbarian Group</span>
			<br />
			<span className={'body'}>
				Developed a rapid, fully functional prototype of the Samsung
				"CenterStage" installation for release in trial department store
				locations.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.youtube.com/watch?v=Nc2yfktuuEY'
				>
					https://www.youtube.com/watch?v=Nc2yfktuuEY
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>BBH Global</strong>
			<br />
			<span className='with'>with Bartle Bogle Hegarty</span>
			<br />
			<span className={'body'}>
				Provided full-stack development for a global agency's site redesign.
				Stack included React, Gatsby, TypeScript, Material-UI, Styled-Components
				and headless WordPress. Developed numerous bespoke components and
				utilities. Automated type safety across the stack.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://bartleboglehegarty.com'
				>
					https://bartleboglehegarty.com
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>#kindawesome</strong>
			<br />
			<span className='with'>with KIND &amp; The Barbarian Group</span>
			<br />
			<span className={'body'}>
				Lead front-end development, managed workflows and did design and
				animation work for a KIND bar promotional fulfilment platform. Developed
				custom animation toolsets specifically for the project.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.adforum.com/creative-work/ad/player/34522374/kindawesome/kind'
				>
					https://www.adforum.com/creative-work/ad/player/34522374/kindawesome/kind
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>CVE-2015-3125</strong>
			<br />
			<span className={'body'}>
				Wrote a 0-day universal cross-site scripting (UXSS) exploit of the Adobe
				Flash plugin. Worked with HP's Zero Day Initiative to securely inform
				Adobe. The exploit proof of concept was capable of secretly reading a
				user's Gmail bypassing Same Origin Policy in all modern browsers.
				Awarded $7500 bounty.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.youtube.com/watch?v=FmD4jppjdcs'
				>
					https://www.youtube.com/watch?v=FmD4jppjdcs
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>New Object Inc.</strong>
			<br />
			<span className={'body'}>
				Founded a digital production shop based in Manhattan. Worked for 3 years
				with numerous brands, startups and agencies including The Martin Agency,
				Barton F. Graf, AKQA, Aereo, PIPS Rewards, Pepsi, Bacardi, Benjamin
				Moore, Dish, IBM, and Samsung.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://newobject.com'
				>
					https://newobject.com
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>Same Days Forever</strong>
			<br />
			<span className='with'>with J Roddy Walston &amp; The Martin Agency</span>
			<br />
			<span className={'body'}>
				Designed and developed a web-based interactive music video for the band
				J. Roddy Walston &amp; The Business. This project won an FWA Site of the
				Day award.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.youtube.com/watch?v=V2oIYfx-diU'
				>
					https://www.youtube.com/watch?v=V2oIYfx-diU
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>First-Person Tetris</strong>
			<br />
			<span className={'body'}>
				Created a web-based video game. Installed at Nikolaj Contemporary Art
				Center in Copenhagen in 2011 and Babycastles in New York in 2013. The
				original game was played over 16 million times.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.youtube.com/watch?v=fWzC-WkHx04'
				>
					https://www.youtube.com/watch?v=fWzC-WkHx04
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>YooouuuTuuube</strong>
			<br />
			<span className={'body'}>
				Created an internet-based video art project that launched in 2009 and
				went through numerous iterations, including iOS and Android apps.
				Installed at the Tate Modern in 2012 and featured at Sundance the same
				year. The original website received over 20 million impressions.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.youtube.com/watch?v=xJbT3dd0Azo'
				>
					https://www.youtube.com/watch?v=xJbT3dd0Azo
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>#MAKEOURMARK</strong>
			<br />
			<span className='with'>with Levi's &amp; AKQA</span>
			<br />
			<span className={'body'}>
				Designed and developed an interactive web-based 3D gallery of instagram
				images tagged with the #makeourmark hashtag for Levi's.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.youtube.com/watch?v=Lg-c1RjiY0w'
				>
					https://www.youtube.com/watch?v=Lg-c1RjiY0w
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>LinkNYC</strong>
			<br />
			<span className='with'>with LinkNYC &amp; Intersection</span>
			<br />
			<span className={'body'}>
				Developed the LinkNYC website.
				<br />
				<a target='_blank' rel='noopener noreferrer' href='https://link.nyc'>
					https://link.nyc
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>Pitchfork Pro</strong>
			<br />
			<span className={'body'}>
				Created a pro version of Pitchfork.com in which their numerical album
				ratings were updated to include 6 more significant digits
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://pbs.twimg.com/media/CRN3HZeUwAAmDJY.png'
				>
					https://pbs.twimg.com/media/CRN3HZeUwAAmDJY.png
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>Man Laws</strong>
			<br />
			<span className='with'>
				with Miller Lite &amp; Crispin Porter + Bogusky
			</span>
			<br />
			<span className={'body'}>
				Provided front-end development and animation for components of the Man
				Laws website.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='http://creativity-online.com/work/miller-lite-man-laws/6770'
				>
					http://creativity-online.com/work/miller-lite-man-laws/6770
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>YouTube Artifact</strong>
			<br />
			<span className={'body'}>
				Created an internet-based glitch art project that has gone through
				numerous iterations. Currently a twitter bot that receives 1 million
				impressions a month.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://twitter.com/youtubeartifact'
				>
					https://twitter.com/youtubeartifact
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>Overtime</strong>
			<br />
			<span className='with'>with The Players' Tribune &amp; ACE</span>
			<br />
			<span className={'body'}>
				Developed a custom interactive gallery of great sports moments for The
				Players' Tribune. Included an infinite generative 3D grid of content,
				and a from-scratch CMS
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='http://overtime.playerstribune.com/'
				>
					http://overtime.playerstribune.com/
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>Funship Island</strong>
			<br />
			<span className='with'>with Carnival Cruise Lines &amp; Razorfish</span>
			<br />
			<span className={'body'}>
				Developed components and oversaw video production for a highly ambitious
				video-based microsite for Carnival Cruise Lines.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.youtube.com/watch?v=ERLNnnpTUZ8'
				>
					https://www.youtube.com/watch?v=ERLNnnpTUZ8
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>Talk Boston</strong>
			<br />
			<span className='with'>with DISH &amp; Barton F. Graf</span>
			<br />
			<span className={'body'}>
				Developed a website for DISH Network's "Talk Boston" campaign. Featured
				a custom CMS, bespoke scrolling animations, and a tweet generator which
				translated any tweet into a Boston accent.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='http://bartonfgraf.com/project/talk-boston/'
				>
					http://bartonfgraf.com/project/talk-boston/
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>Game of Thrones</strong>
			<br />
			<span className='with'>with HBO &amp; Templar Studios</span>
			<br />
			<span className={'body'}>
				Developed a promotional web-based game to raise awareness of season one
				of a little-known upcoming TV show called Game of Thrones.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.youtube.com/watch?v=qpwyiut_PJI'
				>
					https://www.youtube.com/watch?v=qpwyiut_PJI
				</a>
			</span>
		</p>
		<br />
		<p>
			<strong>YouTube Rave</strong>
			<br />
			<span className={'body'}>
				Created a web-based project that launched in 2010. It allowed users to
				enter any YouTube URL and turn it into a rave. It remains my favorite
				thing I've ever made.
				<br />
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.youtube.com/watch?v=0jXJ54vX9fs'
				>
					https://www.youtube.com/watch?v=0jXJ54vX9fs
				</a>
			</span>
		</p>
		<br />

		<em>Full portfolio includes projects for brands including:</em>
		<p className={'body'}>
			AT&amp;T, Benjamin Moore, Bacardi, Best Buy, Bravo, Captain Morgan, The
			Cayman Islands, Cocoavia, Delonghi, Ford, Friendly's, Goldman Sachs, IBM,
			Mercedes-AMG, Microsoft, Morgan Stanley, Nature's Care, Nike, Pepsi, Ping,
			PNC Bank, San Pellegrino, SC Johnson, Smart, Raid, Ralph Lauren, Sony
			&amp; Sprint
		</p>
	</div>
);
