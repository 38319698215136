import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { random, shuffle } from 'lodash';

type Props = {
	onShrug?:() => any;
	skipShrug?:boolean;
};

const ShrugDiv = styled.div`
	perspective: 600;
	perspective-origin: 0% 0%;

	font-size: 19px;
`;

const transTime = 1.8;

const ShrugSpan = styled.div<{
	transform:string;
	delay:number;
}>`
	backface-visibility: visible;
	user-select: none;
	display: inline-block;
	transition: all ${transTime}s ${p => p.delay}s
		cubic-bezier(0.52, 1.375, 0.62, 1.01);
	transform: ${p => p.transform};

	&.loaded {
		transform: translate3d(0px, 0px, 0px) rotate3d(0, 0, 0, 0deg);
	}
`;

export const Shrug:React.FC<Props> = props => {
	const shrugRef = useRef<HTMLDivElement>();

	const chars = `¯\\_(ツ)_/¯`.split('');

	const charDelay = 0.5;

	const starts = shuffle(
		chars.map((char, i) => ({
			x: props.skipShrug ? 0 : random(-50, 0),
			y: props.skipShrug ? 0 : random(0, 50),
			z: props.skipShrug ? 0 : random(1400, 1800),
			rotX: props.skipShrug ? 0 : random(0, 1),
			rotY: props.skipShrug ? 0 : random(0, 1),
			rotZ: props.skipShrug ? 0 : random(0, 0.8),
			rot: props.skipShrug ? 0 : random(-1260, 1260),
			delay: props.skipShrug ? 0 : i * charDelay,
		})),
	);

	const [loaded, setLoaded] = useState(false);
	const startsRef = useRef(starts);

	useEffect(() => {
		setLoaded(true);
		setTimeout(() => {
			props.onShrug && props.onShrug();
		}, (charDelay * chars.length + transTime - 0.5) * 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ShrugDiv ref={shrugRef}>
			{chars.map((char, i) => {
				const s = startsRef.current[i];
				const translation = `translate3d(${s.x}px, ${s.y}px, ${s.z}px)`;
				const rotation = `rotate3d(${s.rotX},${s.rotY},${s.rotZ},${s.rot}deg)`;
				return (
					<ShrugSpan
						delay={s.delay}
						className={loaded && `loaded`}
						transform={translation.concat(' ', rotation)}
						key={i}
					>
						{char}
					</ShrugSpan>
				);
			})}
		</ShrugDiv>
	);
};
