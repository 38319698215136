import React, { useState } from 'react';
import { Nav } from './Nav';
import { Shrug } from './Shrug';
import { GlobalStyle } from '../style/GlobalStyle';
import { DKPageProps } from '../gatsby/createPages';
import { CutInDiv } from '../style/CutInDiv';
import { Helmet } from 'react-helmet';

export type Props = DKPageProps;

export const Layout:React.FC<Props> = props => {
	const [shrugged, setShrugged] = useState(false);

	const waitForShrug = true;

	const { preview } = props.pageContext;

	if (preview)
		return (
			<GlobalStyle>
				<Nav preview={preview} page={props.pageContext.page} />
				{props.children}
			</GlobalStyle>
		);
	else
		return (
			<GlobalStyle>
				<Helmet>
					<title>davidkraftsow.com</title>
					<meta charSet='utf-8' />
					<meta httpEquiv='X-UA-Compatible' content='IE=edge' />
					<meta name='viewport' content='width=device-width, initial-scale=1' />
					<meta name='robots' content='nofollow, disallow, noindex' />
				</Helmet>
				<div className='temp' />
				<Shrug onShrug={() => setShrugged(true)} />
				{(shrugged || !waitForShrug) && (
					<>
						<Nav page={props.pageContext.page} />
						<CutInDiv style={{ zIndex: 999 }} delay={1.5}>
							{props.children}
						</CutInDiv>
					</>
				)}
			</GlobalStyle>
		);
};
