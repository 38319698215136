import React from 'react';
import { about } from '../content/sections/about';
import { projects } from '../content/sections/projects';
import { press } from '../content/sections/press';
import { stack } from '../content/sections/stack';
import { contact } from '../content/sections/contact';
import { CodeSection } from '../code/CodeSection';

export const sections = {
	about: about,
	press: press,
	projects: projects,
	stack: stack,
	code: <CodeSection />,
	contact: contact,
};
