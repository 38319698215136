/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CodeQuery } from '../graphql/types';
import { CodeExample } from './CodeExample';
import styled from 'styled-components';

export type Props = {};

const Intro = styled.div`
	margin-bottom: 40px;
	font-style: italic;
`;

export const CodeSection:React.FC<Props> = () => {
	const result:CodeQuery = useStaticQuery(graphql`
		query CodeQuery {
			allRawCode {
				edges {
					node {
						name
						content
					}
				}
			}
		}
	`);

	const getSnippet = (name:string) => {
		const edge = result.allRawCode.edges.find(e => e.node.name === name);
		return edge ? edge.node.content : null;
	};

	return (
		<div>
			<Intro>
				Some descriptions of code I've written recently. Mostly React components
				and TypeScript/Node utilities. Access to npm/GitHub upon request
			</Intro>
			<CodeExample title={`addToMoment`} snippet={getSnippet('add-to-moment')}>
				A Node utility for adding tweets to Twitter Moments. Twitter does not
				support adding tweets to Moments via their developer API, but this
				utility can do it.
			</CodeExample>
			<CodeExample title={`avatarClock`} snippet={getSnippet('avatar-clock')}>
				A Node utility that sets a cron task which updates a Twitter account's
				avatar in a loop over a given period. I use it to make&nbsp;
				<a
					href='https://twitter.com/dontsave'
					target='_blank '
					rel='noopener noreferrer'
				>
					my avatar's
				</a>
				&nbsp;dropshadow rotate to match the time of day.
			</CodeExample>
			<CodeExample title={`KeyedSet`} snippet={getSnippet('keyed-set-0')}>
				A data structure which extends ES6 Set to allow for objects, tuples etc
				to serve as reducible <em>non-unique</em> keys. A keyify function is
				passed to the KeyedSet which takes in the object and returns a primitive
				which serves as the composed Set key.
				<br />
				<br />
				This allows for quick shorthand set adding and getting, useful for
				easily referencing multidimensional data. You could use tuples to store
				and reference data from a 2-dimensional grid, for example.
			</CodeExample>
			<CodeExample
				noMargin
				title={`KeyedMap`}
				snippet={getSnippet('keyed-map-0')}
			>
				Similar to above, KeyedMap extends ES6 Map to allow for objects and
				tuples to non-uniquely correlate to any value using a keyify function.
			</CodeExample>
			<CodeExample snippet={getSnippet('keyed-iterable')}>
				Like Sets and Maps, KeyedSets and KeyedMaps are Iterables. Unlike Sets
				and Maps, KeyedSets and KeyedMaps include utility functions like map,
				reduce and filter.
			</CodeExample>
			<CodeExample
				title={`diffCollection`}
				snippet={getSnippet('diff-collection')}
			>
				An overloaded utility function which returns the difference between
				Sets, Maps, KeyedSets and KeyedMaps. Useful since Lodash does not
				support operations on ES6 data structures.
			</CodeExample>
			<CodeExample
				title={`GenerativeGrid`}
				snippet={getSnippet('generative-grid')}
			>
				A React component that renders an infinite generative grid (or
				"masonry") layout that updates as it changes position within a viewport.
				The grid is specified via a function prop which maps coordinates to
				shapes, and a second function prop which maps shapes to ReactNodes.
				Nodes are lazily added and removed from the viewport. They are also
				passed a GridContext from which they are provided information about the
				grid and their location within it.
				<br />
				<br />
				The below interface fills a grid with 4 possible shapes. If the user
				scrolls to a coord left of x=10, They'll see either red or purple divs.
				If the user scrolls right, they'll see blue or cornsilk divs with drop
				shadows.
				<br />
				<br />
				<a
					href={`https://www.youtube.com/watch?v=Lg-c1RjiY0w`}
					target='_blank'
					rel='noopener noreferrer'
				>
					Here's
				</a>{' '}
				an example of an earlier iteration of this component in a project I did
				for Levi's.
			</CodeExample>
			<CodeExample title={`useTileGrid`} snippet={getSnippet('use-tile-grid')}>
				The GenerativeTileGrid component above composes this hook which manages
				its state:
			</CodeExample>
			<CodeExample title={`Grid2D`} snippet={getSnippet('grid2d')}>
				A data structure class which keeps track of shapes mapped to a 2D grid.
				Includes numerous utility functions for managing shapes within the
				grid's viewport.
			</CodeExample>
			<CodeExample title={`ds`} snippet={getSnippet('ds')} language={`bash`}>
				Command-line set of node tools I've written for streamlining work.
				Includes React and Node custom template generators, a utility which
				forwards an express server to point to a running ngrok tunnel and tools
				for resizing imagery, maintaining pm2 processes, crons etc.
			</CodeExample>
			<CodeExample
				title={`generate-youtube-artifact`}
				snippet={getSnippet('generate-youtube-artifact')}
				language={`bash`}
			>
				Command-line tool to generate glitch art from youtube videos. This
				command is running on a node cron and powers my&nbsp;
				<a
					href='https://thenextweb.com/twitter/2017/10/12/twitter-bot-beautiful-art-glitchy-youtube-videos/'
					target='_blank '
					rel='noopener noreferrer'
				>
					@youtubeartifact
				</a>
				&nbsp;project.
			</CodeExample>
			<CodeExample title={`Zoetrope`} snippet={getSnippet('zoetrope')}>
				Ongoing React/webgl port of some of the core effects of my old&nbsp;
				<a
					href='https://www.youtube.com/watch?v=xwIuREcILtc'
					target='_blank '
					rel='noopener noreferrer'
				>
					YooouuuTuuube
				</a>
				&nbsp;video visualizer project.
			</CodeExample>
			<CodeExample
				title={`useMeasurement`}
				snippet={getSnippet('use-measurement')}
			>
				A hook which subscribes to scroll events, resize observers, intersection
				observers or raf events, and returns stateful measurements of an Element
				or Window's ClientRect when they change. Scroll events can be read from
				any scroll dispatcher.
			</CodeExample>
			<CodeExample
				title={`useScrollInfo`}
				snippet={getSnippet('use-scroll-info')}
			>
				A hook which composes useMeasurement, gathering info about an Element's
				relationship to a viewport (a Window or other Element). Calculates
				visibility, and an array of typed ratio relations between the two
				ClientRects. Composes some of the functionality of IntersectionObserver.
				This could be used for lazy loading, scroll-based animations or
				parallax.
			</CodeExample>
			<CodeExample title={`LazyDiv`} snippet={getSnippet('lazy-div')}>
				A stylable div which composes useScrollInfo and renders its children
				only in the event that it appears within a viewport (either another
				containing element, or the Window by default). Can be set to be
				impermanent, meaning it will unmount its children upon exiting the
				viewport. This page is currently using this component to render
				snippets, so open the inspector to see it in action.
			</CodeExample>
			<CodeExample
				title={`FixedStaticDiv`}
				snippet={getSnippet('fixed-static-div')}
			>
				A React component which renders a fixed position div that behaves in the
				way a static positioned div would. The component renders a skeleton
				static div which interacts with the document flow, and matches the fixed
				div to its size and position via scroll events, resize observers or raf.
				This is primarily useful for preventing jank in scroll-based animations
				or parallax effects.
			</CodeExample>
			<CodeExample title={`Section`} snippet={getSnippet('scroll-section')}>
				Composes useScrollInfo and renders a parent div which passes through a
				SectionContext, providing visibility, viewport and ratio measurements to
				its children. Sections optionally calculate the upcoming viewport and
				pass it down through context <em>before</em> the next paint, allowing
				children to make animation/parallax adjustments to avoid scroll jitter.
				Children can be rendered to a normal (default) or fixedstatic parent
				div.
			</CodeExample>
			<CodeExample
				title={`ParallaxContainer`}
				snippet={getSnippet('parallax-container')}
			>
				Renders a containing div which translates its children relative to an
				axis and a parallax scalar value. Composes a Section which can be set to
				use a FixedStaticDiv to reduce scroll jitter.
			</CodeExample>
			<CodeExample title={`Parallax2D`} snippet={getSnippet('parallax-2d')}>
				Renders a div which translates its children in 2D space in relation to a
				focal point, a Vec2 position and a viewport rect passed down through
				SectionContext. This could be used to give the effect of objects moving
				on various planes in space as the user scrolls.
			</CodeExample>
			<CodeExample title={`FocalImage`} snippet={getSnippet('focal-image')}>
				React Component which wraps GatsbyImage, allowing for an additional
				focal point property to be passed as a Vec2. The GatsbyImage is scaled
				and position relative to the focal point using object-fit and
				object-position with polyfill fallbacks.
			</CodeExample>
			<CodeExample title={`Fluid & FluidFont`} snippet={getSnippet('fluid')}>
				Styled Component mixins which allow css properties to be assigned a vw
				unit that will be clamped to calculated min and max px values.
			</CodeExample>
			<CodeExample title={`DragArea`} snippet={getSnippet('drag-area')}>
				A React component providing an abstract drag with friction functionality
				to any other composing component.
			</CodeExample>
			<CodeExample title={`DeepReadonly`} snippet={getSnippet('deep-readonly')}>
				A Typescript utility which enforces Readonly on arbitrarily deeply
				nested objects
			</CodeExample>
			<CodeExample
				noMargin
				title={`EnforceDiff`}
				snippet={getSnippet('enforce-diff')}
			>
				A Typescript utility which disallows compatibility between types with
				different members
			</CodeExample>
			<CodeExample snippet={getSnippet('enforce-immutable')}>
				EnforceDiff can be used to enforce assignment of immutable versions of
				objects to types. This is useful when working with React props, for
				example, in enforcing that prop and state objects are immutable.
			</CodeExample>
			<CodeExample title={`DeepPartial`} snippet={getSnippet('deep-partial')}>
				A Typescript utility which allows Partial contract fulfilment on
				arbitrarily deeply nested objects
			</CodeExample>
			{/* <CodeExample title={`ConstTuple`} snippet={getSnippet('const-tuple')}>
				A Typescript utility which enforces shallow Readonly on Tuple types
			</CodeExample> */}
		</div>
	);
};
