import React, { ReactNode } from 'react';
import { PrismAsyncLight as Hilighter } from 'react-syntax-highlighter';
import { coy as style } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import styled from 'styled-components';
import { LazyDiv } from '@dontsave/scroll-effects';

export type Props = {
	children:ReactNode;
	snippet:string;
	title?:string;
	language?:string;
	noMargin?:boolean;
};

const Holder = styled.div<{ noMargin?:boolean }>`
	margin-bottom: ${p => (p.noMargin ? 30 : 60)}px;
	h2 {
		font-weight: bold;
		margin-bottom: 30px;
	}
`;

const SnippetHolder = styled.div`
	border: 1px solid black;
	max-height: 250px;
	overflow: auto;
	padding-left: 2vw;
	padding-top: 2vw;
	padding-bottom: 2vw;

	pre > code {
		display: inline-block;
		padding-right: 2vw;
	}
`;

const Description = styled.div`
	margin-bottom: 30px;
`;

export const CodeExample:React.FC<Props> = props => {
	return (
		<Holder noMargin={props.noMargin}>
			{props.title && <h2>{props.title}</h2>}
			<Description>{props.children}</Description>
			<LazyDiv style={{ minHeight: `50px` }}>
				<SnippetHolder>
					<Hilighter
						wrapLines
						customStyle={{ maxWidth: 200 }}
						language={props.language || `tsx`}
						style={style}
					>
						{props.snippet.replace(/^\s+|\s+$/g, '')}
					</Hilighter>
				</SnippetHolder>
			</LazyDiv>
		</Holder>
	);
};
