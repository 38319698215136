import React from 'react';
import { WrapPageElement } from './types/WrapPageElement';
import { Layout } from '../layout/Layout';

export default (({ element, props }) => {
	return props.pageContext.resume ? (
		element
	) : (
		<Layout {...props}>{element}</Layout>
	);
}) as WrapPageElement<{ page:string; preview:boolean; resume:boolean }>;
