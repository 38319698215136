/* eslint-disable react/no-unescaped-entities */
import React from 'react';
export const about = (
	<div>
		<p className='header'>
			Hi there. I'm a tech generalist who has been building digital projects for
			global brands since 2000.
			<br />
			<br />
			My current interests are in the Node/React ecosystem with special focus on
			cross-stack type safety, static site generation, design systems and
			managing workflows that bridge the gap between creatives and developers.
			<br />
			<br />
			My creative technology projects have received millions of impressions and
			been featured at the Tate Modern, the Museum of the Moving Image,
			Copenhagen Contemporary Art Center and a yoga studio in Park City with DJ
			A-Trak.
			<br />
			<br />I once wrote a 0-day UXSS same-origin policy exploit of Adobe Flash
			which could read anyone's Gmail. Coolio, Jimmy Wales and Pee-wee Herman
			have endorsed my work.
		</p>
		<br />
		<p className='header'>
			<strong>Professional History</strong>
		</p>
		<p>
			2016 - Present
			<br />
			<span className='body'>
				<strong>Front-end Tech Consultant</strong>
				<br />
				Various, New York
				<br />
				<br />
				Currently serving as a technology consultant specializing in the React
				ecosystem on projects for agencies and brands including Kraken, Bartle
				Bogle Hegarty, ACE, San Pellegrino, For Days and The Players' Tribune.
			</span>
		</p>
		<p>
			2015 - 2016
			<br />
			<span className='body'>
				<strong>Technology Lead</strong>
				<br />
				The Barbarian Group, New York
				<br />
				<br />
				Lead front-end development team on a largescale promotional fulfillment
				platform. Provided creative concepting, ideation, animation and new
				business development. Worked on brands including KIND, Samsung and
				Pepsi.
			</span>
		</p>
		<p>
			2012 - 2015
			<br />
			<span className='body'>
				<strong>Founding Partner</strong>
				<br />
				New Object Inc., New York
				<br />
				<br />
				Partner in a digital production shop in Manhattan's Chelsea
				neighborhood. Serviced agencies and brands including Anomaly, The Martin
				Agency, Barton F. Graf, Pepsi, Bacardi, Benjamin Moore, Levi's, Dish,
				IBM, Microsoft and Samsung. Managed freelancers, pitched new business
				and did development, design and animation in the creation of websites,
				prototypes and digital advertising.
			</span>
		</p>
		<p>
			2006 - 2012
			<br />
			<span className='body'>
				<strong>Freelance Technologist</strong>
				<br />
				Various, New York
				<br />
				<br />
				Provided front and back-end development, design and animation work for
				agencies and brands including Razorfish, Wieden+Kennedy, Zubi, The
				Martin Agency, Google, Mercedes-AMG, Ralph Lauren, Nike, Carnival, Ford,
				Morgan Stanley, Best Buy, and Sony among others. Created microsites and
				rich media advertising. Created a number of personal projects that went
				viral, garnering millions of views.
			</span>
		</p>
		<p>
			2004
			<br />
			<span className='body'>
				<strong>B.S., Computer Science</strong>
				<br />
				University of Central Florida
				<br />
				<br />
				Electives included relational database management, programming
				languages, 20th century art and intro to point-set topology.
			</span>
		</p>
		<p>
			2000 - 2005
			<br />
			<span className='body'>
				<strong>Freelance Technologist</strong>
				<br />
				Various, Orlando & Miami
				<br />
				<br />
				Worked for PUSH, MMGY and Crispin Porter + Bogusky doing early Web 1.0
				digital advertising and user-generated content sites. Involved in
				development, design and animation for brands including Miller, Disney,
				Marriott, the Colorado Dept of Tourism and Kaiser Health.
			</span>
		</p>
	</div>
);
