import React from 'react';
export const contact = (
	<div>
		<strong>Email</strong>
		<p>
			<a
				className='body'
				target='_blank'
				rel='noopener noreferrer'
				href='mailto:dave@davidkraftsow.com'
			>
				dave@davidkraftsow.com
			</a>
		</p>
		<strong>Twitter</strong>
		<p>
			<a
				className='body'
				target='_blank'
				rel='noopener noreferrer'
				href='https://twitter.com/dontsave'
			>
				@dontsave
			</a>
		</p>
		<strong>GitHub</strong>
		<br />
		<p className='body'>
			<a
				target='_blank'
				rel='noopener noreferrer'
				href='https://github.com/dontsave'
			>
				https://github.com/dontsave
			</a>
			<br />
			[access upon request]
		</p>
		<br />
	</div>
);
