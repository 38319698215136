import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { resetCSS } from './reset';

const GatsbyGlobal = createGlobalStyle`
	${resetCSS}
	
	body {
		font-family:Arial, Helvetica, sans-serif;
		padding-top:10vh;
		padding-bottom:10vh;
		padding-left:10vw;
		padding-right:10vw;
		line-height:24px;
		font-size:13px;
	}
	
	strong {
		font-weight:bold;
	}
	
	p {
		margin-bottom:30px;
	}
	
	em {
		font-style: italic;
	}
	
	.with {
		font-style: italic;
	}
	
	.body {
		padding-left: 30px;
		display:inline-block;
		margin-top:20px;
	}
	
	a {
		display:inline-block;
		word-wrap: break-word;
		word-break: break-all;
	}
	
	
`;

export type Props = {};

export const GlobalStyle:React.FC<Props> = p => {
	return (
		<>
			<GatsbyGlobal />
			{p.children}
		</>
	);
};
