import React from 'react';
import GatsbyLink from 'gatsby-link';
import styled from 'styled-components';

export type Props = {
	section:string;
	active?:boolean;
	preview?:boolean;
};

// const getPos = (base?:boolean) => ({
// 	x: base ? 0 : 0,
// 	y: base ? 0 : 0,
// 	z: base ? 0 : 100,
// 	rotX: base ? 0 : random(0, 1),
// 	rotY: base ? 0 : random(0, 1),
// 	rotZ: base ? 0 : random(0, 1),
// 	rot: base ? 0 : 0,
// });

// const frames = () => keyframes`
// 	from {
// 		transform: ${getTransform(getPos())};
// 		/* opacity:0; */
// 	}

// 	to {
// 		transform: ${getTransform(getPos(true))}
// 	}
// `;

const NavItemHolder = styled.div`
	display: inline-block;
	perspective: 500;
	perspective-origin: 0% 0%;

	a {
		cursor: pointer;
		padding-bottom: 1px;
		position: relative;
		&.active {
			&::after {
				backface-visibility: visible;
				content: ' ';
				width: 100%;
				position: absolute;
				bottom: 0px;
				left: 0px;
				border-bottom: 1px solid black;
			}
		}
		display: inline-block;
		/* width: 50px; */
		&.active {
			cursor: default;
		}

		text-decoration: none;
		color: black;
		margin-right: 30px;
		&:visited {
			color: black;
		}
	}

	@media (max-width: 490px) {
		width: 80px;
		margin-bottom: 7px;
		margin-top: 7px;
	}
`;

export const NavItem:React.FC<Props> = props => {
	const to = props.preview
		? `/___preview/${props.section}`
		: `/${props.section}`;

	return (
		<NavItemHolder>
			<GatsbyLink className={props.active ? 'active' : ''} to={to}>
				{/* {chars.map((char, i) => {
					return (
						<Char key={i} active={props.active}>
							{char}
						</Char>
					);
				})} */}
				{props.section}
			</GatsbyLink>
		</NavItemHolder>
	);
};
