import React from 'react';
export const stack = (
	<div>
		<p>
			<strong>Tech</strong>
			<span className='body'>
				Node, Express, React, Redux, TypeScript, Jest, GraphQL, Webpack, Sass,
				Styled Components, Material-UI, Gatsby, Contentful, WordPress, Firebase,
				Netlify, CircleCI, Docker, Vagrant, Apache, MySQL, PHP, AS3, Java, C,
				Linux, Windows, OS X, FFmpeg, Jira, Pivotal, GitHub, IntelliJ, VSCode,
				etc.
			</span>
			<br />
			<br />
			<br />
			<strong>Creative</strong>
			<span className='body'>
				Ideation, Prototyping, Experience Design, Responsive Design, Generative
				Design, Game Design, Social Messaging, Viral Marketing, Animation, UX
				Consulting, Digital Media Production, Pitch Development, Photoshop,
				Illustrator, After Effects, Animate, etc.
			</span>
		</p>
	</div>
);
