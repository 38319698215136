import React from 'react';
import { sections } from './sections';
import { CutInDiv } from '../style/CutInDiv';
import styled from 'styled-components';
import { NavItem } from './NavItem';

export type Props = {
	page?:string;
	preview?:boolean;
};

const NavHolder = styled.div`
	margin-top: 20px;
	line-height: 19px;
	.nav-items {
		margin-top: 40px;
		margin-bottom: 40px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
`;

export const Nav:React.FC<Props> = props => {
	if (props.preview)
		return (
			<NavHolder>
				<div>david kraftsow</div>
				<div>technologist for hire</div>
				<div className={`nav-items`}>
					{Object.keys(sections).map(section => (
						<NavItem
							preview
							active={props.page === section}
							key={section}
							section={section}
						/>
					))}
				</div>
			</NavHolder>
		);
	else
		return (
			<NavHolder>
				<CutInDiv delay={0}>david kraftsow</CutInDiv>
				<CutInDiv delay={0.5}>technologist for hire</CutInDiv>
				<CutInDiv className={`nav-items`} delay={1}>
					{Object.keys(sections).map(section => (
						<NavItem
							active={props.page === section}
							key={section}
							section={section}
						/>
					))}
				</CutInDiv>
			</NavHolder>
		);
};
